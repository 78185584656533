import React from "react"
import { graphql } from "gatsby"
import get from "lodash/get"
import BackgroundImage from "gatsby-background-image"
import Layout from "../components/Layout"
import styled from "styled-components"
import SEO from "../components/seo"
import PageIntro from "../components/PageIntro"

export const AddYourCityPageTemplate = ({
  page_intro = {},
  form_url,
  form_height = 1200,
  isPreview = false,
  fluidImage,
  fluidImageB
}) => (
    <AddYourCityWrapper className={`${isPreview ? "is-preview" : ""}`}>
      <section className="page-header">
        {fluidImageB && (
          <div className="page-header__background">
            <ImageBackground Tag="div"
              fluid={fluidImageB}
              fadeIn={"soft"}
              style={{
                backgroundPosition: "top right",
                backgroundSize: "contain"
              }}
            />
          </div>
        )}
        <div className="container">
          <div className="inner-container">
            <div className="columns no-gutter">
              <div className="column column-6">
                <PageIntro {...page_intro} />
              </div>
              <div className="column content-b column-6">
                {fluidImage && (
                  <ImageBackground Tag="div"
                    fluid={fluidImage}
                    fadeIn={"soft"}
                    style={{
                      backgroundPosition: "top left",
                      backgroundSize: "contain"
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      {form_url && (
        <section className="add-city-form-container" id="add-your-city-form">
          <iframe title="Add Your City" width="100%" height={`${form_height}px`} src={form_url} />
        </section>
      )}
    </AddYourCityWrapper>
  )

const AddYourCity = ({ data: { markdownRemark: { frontmatter }, backgroundImage, backgroundImageB } }) => {
  if (typeof window !== `undefined`) {
    window.location.replace(`/`)
    return <div></div>
  }
  const { title } = frontmatter
  const fluidImage = get(backgroundImage, "childImageSharp.fluid")
  const fluidImageB = get(backgroundImageB, "childImageSharp.fluid")
  return (
    <Layout className="add-your-city">
      <SEO title={title} />
      <AddYourCityPageTemplate {...frontmatter} fluidImage={fluidImage} fluidImageB={fluidImageB} />
    </Layout>
  )
}

const ImageBackground = styled(BackgroundImage)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  background-repeat: no-repeat;
`

const AddYourCityWrapper = styled.div`
  background: ${({ theme }) => theme.offWhite};
  z-index: 1;

  .page-header {
    position: relative;
    background: rgba(136, 49, 122, 0.05);
    z-index: -1;
    padding: 2.5rem 0 0;

    @media screen and (min-width: ${({ theme }) => theme.tablet}) {
      padding: 7.5rem 0 0;
    }

    &__background {
      display: none;
      position: absolute;
      width: 50%;
      height: calc(100% - 5.5rem);
      top: -5rem;
      left: 50%;
      overflow: hidden;
      
      body.is-ie & {
        top: 0;
      }

      @media (min-width: 1100px) {
        display: block;
      }
    }
  }

  .page-intro {
    margin-bottom: 5rem;
    @media screen and (min-width: ${({ theme }) => theme.tablet}) {
      margin-bottom: 17.5rem;

      body.is-ie & {
        margin-bottom: 5rem;
      }
    }
  }

  .add-city-form-container {
    width: 100%;
    margin: -10rem auto 0;
    padding-top: 5rem;
    padding-bottom: 3rem;
    z-index: 2;

    body.is-ie & {
      margin: 0 auto;
      padding-top: 2.5rem;
    }
  }

  &.is-preview {
    .page-header {
      z-index: 0;
    }
  }

  body.is-ie & {
    .page-header {
      z-index: 0;
    }
  }
`

export const pageQuery = graphql`
  query {
    markdownRemark(frontmatter: { templateKey: { eq: "add-your-city-page" } }) {
      frontmatter {
        title
        page_intro{
          heading
          subtitle
          subheading
          text
          buttons{
            class
            text
            href
          }
        }
        form_url
        form_height
      }
    }
    backgroundImage: file(relativePath: { eq: "add-your-city-bg-a.png" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    backgroundImageB: file(relativePath: { eq: "add-your-city-bg-b.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

export default AddYourCity